import React, {useEffect, useState} from 'react'
import {
    Card,
    CardActions,
    CardContent,
    Checkbox,
    ListSubheader,
    MenuItem,
    Paper,
    TextField,
    Typography
} from "@material-ui/core";
import {
    createStandard,
    findAllInternalCodes,
    findStandardById,
    Harmonized,
    InternalCodeDto,
    StandardDto,
    updateStandard
} from "../../api/StandardApi";
import {navigate} from "gatsby";
import {useForm} from "react-hook-form";

import Autocomplete, {AutocompleteRenderGroupParams} from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import Header from "../common/Header";
import ButtonStyled from "../common/ButtonStyled";

import {useStyles} from "../../styles/CommonStyles";
import {useTranslation} from 'react-i18next';
import BackendError from "../common/BackendError";
import ConfirmDialog from "../common/ConfirmDialog";

interface StandardCreateEditProps{
    id?: number,
    type: "edit" | "create",
    style?:any,
}

export default function StandardCreateEdit( props: StandardCreateEditProps){
    const classes = useStyles();
    const { t,i18n } = useTranslation();


    const [standard, setStandard] = React.useState(null);
    const [internalCodesFull, setInternalCodesFull] = React.useState<InternalCodeDto[]>(null);

    const [age, setAge] = React.useState('');


    const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
    const [backendErrorMessage, setBackendErrorMessage] = useState(null)

    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm<StandardDto>();
    const nameRegister = register("name",{ required: true });
    const englishNameRegister = register("englishName");
    const validityRegister = register("validity");
    const harmonizedRegister = register("harmonized");
    const codeRegister = register("code",{ required: true });
    const catalogNumberRegister = register("catalogNumber",{ required: true, pattern: /[0-9]/ });
    const anotationRegister = register("annotation");
    const internalCodesRegister = register("internalCodes");

    useEffect(() => {
        if(props.type=="edit"){
            findStandardById(props.id).then(res => {
                findAllInternalCodes(null,null).then(icodes => {
                    setInternalCodesFull(icodes)
                    let tmp = []
                    res.internalCodes.forEach(code => {
                        tmp.push(icodes.find(icode => icode.id == code))
                    })
                    res.internalCodes = tmp
                    setValue("internalCodes",tmp)
                    setStandard(res)
                });
            }).catch((err)=>{navigate("/404")})
        }else{
            findAllInternalCodes(null,null).then(icodes => {
                setInternalCodesFull(icodes)
                setValue("internalCodes",[])
                setStandard({name: "", code:"", catalogNumber: "", url: "", internalCodes: [], annotation: ""})
            });
        }
    },[]);

    const handleInternalCodesSelect = (values) => {
        if(values!=undefined){
            setValue("internalCodes",values )
        }else{
            setValue("internalCodes",[] )
        }
    }

    const handleHarmonizedChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue("harmonized", event.target.value as Harmonized);
    };

    const onSubmit = handleSubmit(({name,englishName, validity, harmonized, code,catalogNumber, url, internalCodes,annotation}) => {
        if(props.type=="edit"){
            // @ts-ignore
            updateStandard(props.id,{name: name, englishName:englishName,validity:validity, harmonized:harmonized, code:code, catalogNumber: Number(catalogNumber), internalCodes: internalCodes.map(i=>Number(i.id)), annotation: annotation}).then((res)=>{
                    navigate("/standards/"+res.id);
            }).catch(error=> {
                if(error.response!=undefined) {
                    setBackendErrorMessage(i18n.language=='cs' ? error.response.data.czechMessage!=null ? error.response.data.czechMessage : error.response.data.message : error.response.data.message);
                }else setBackendErrorMessage(t("ERRDATAERROR"));
                setSubmitDialogOpen(false)
            })
        }else{
            // @ts-ignore
            createStandard({name: name, code:code, catalogNumber: Number(catalogNumber), internalCodes: internalCodes.map(i=>Number(i.id)), annotation: annotation}).then(res=>{
                navigate("/standards/"+res.id);
            }).catch(error=>{
                if(error.response!=undefined) {
                    setBackendErrorMessage(i18n.language=='cs' ? error.response.data.czechMessage!=null ? error.response.data.czechMessage : error.response.data.message : error.response.data.message);
                }else setBackendErrorMessage(t("ERRDATAERROR"));
                setSubmitDialogOpen(false)
            })
        }

    });

    const renderGroup = (params: AutocompleteRenderGroupParams) => [
        <ListSubheader key={params.key} component="div">
            {params.group}
        </ListSubheader>,
        params.children,
    ];

    return (
        <Paper className={classes.root} style={props.style}>
            {(standard!= null && internalCodesFull!=null) ?
                <form onSubmit={onSubmit} noValidate autoComplete="off">
                    <Card variant="outlined">
                        <Header title={props.type=="edit" ? t("STANDARDEDIT") : t("STANDARDCREATE")} titleTypographyProps={{variant:"h5", align:"center"}}/>
                        <CardContent>
                            {backendErrorMessage!=null && <BackendError error={backendErrorMessage}/>}
                            <Typography className={classes.fieldTitle} variant="h6">
                                {t("NAME")}
                            </Typography>
                            <TextField
                                fullWidth
                                className={classes.fieldContent}
                                id="name"
                                type="text"
                                size="small"
                                placeholder={t("STANDARDNAME")}
                                inputRef={nameRegister.ref}
                                onChange={nameRegister.onChange}
                                onBlur={nameRegister.onBlur}
                                name={nameRegister.name}
                                inputProps={{
                                    defaultValue: standard.name
                                }}
                            />
                            {errors.name && (<div className={`${classes.formErrors} ${classes.fieldContent}`}>Fill in name of Standard</div>)}
                            <Typography className={classes.fieldTitle} variant="h6">
                                {t("ENGLISH_NAME")}
                            </Typography>
                            <TextField
                                fullWidth
                                className={classes.fieldContent}
                                id="englishName"
                                type="text"
                                size="small"
                                placeholder={t("ENGLISH_NAME")}
                                inputRef={englishNameRegister.ref}
                                onChange={englishNameRegister.onChange}
                                onBlur={englishNameRegister.onBlur}
                                name={englishNameRegister.name}
                                inputProps={{
                                    defaultValue: standard.englishName
                                }}
                            />
                            <Typography className={classes.fieldTitle} variant="h6">
                                {t("INTERNALCODES")}
                            </Typography>
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={internalCodesFull}
                                disableCloseOnSelect
                                filterSelectedOptions
                                defaultValue={standard.internalCodes}
                                getOptionLabel={(icode) => `${icode.name} - ${icode.type}`}
                                style={{ width: 500 }}
                                onChange={(event,values) => handleInternalCodesSelect(values)}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label={t("SELECTINTERNALCODES")} placeholder={t("INTERNALCODES")} />
                                )}
                            />
                            <Typography className={classes.fieldTitle} variant="h6">
                                {t("STANDARDCODE")}
                            </Typography>
                            <TextField
                                fullWidth
                                id="code"
                                className={classes.fieldContent}
                                type="text"
                                size="small"
                                placeholder={t("STANDARDCODE")}
                                inputRef={codeRegister.ref}
                                onChange={codeRegister.onChange}
                                onBlur={codeRegister.onBlur}
                                name={codeRegister.name}
                                inputProps={{
                                    defaultValue: standard.code
                                }}
                            />
                            {errors.code && (<div className={`${classes.formErrors} ${classes.fieldContent}`}>{t("FILLSTANDARDCODE")}</div>)}
                            <Typography className={classes.fieldTitle} variant="h6">
                                {t("VALIDITY")}
                            </Typography>
                            <TextField
                                id="date"
                                label={t("VALIDITY")}
                                className={classes.fieldContent}
                                type="date"
                                inputRef={validityRegister.ref}
                                onChange={validityRegister.onChange}
                                onBlur={validityRegister.onBlur}
                                name={validityRegister.name}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    defaultValue: standard.validity
                                }}
                            />
                            <Typography className={classes.fieldTitle} variant="h6">
                                {t("HARMONIZED")}
                            </Typography>
                            <TextField
                                id="harmonized"
                                className={classes.fieldContent}
                                inputRef={harmonizedRegister.ref}
                                onChange={harmonizedRegister.onChange}
                                onBlur={harmonizedRegister.onBlur}
                                name={harmonizedRegister.name}
                                inputProps={{
                                    defaultValue: standard.harmonized!=null ? standard.harmonized : Harmonized.NO
                                }}
                                select>
                                <MenuItem value={Harmonized.YES}>{t("YES")}</MenuItem>
                                <MenuItem value={Harmonized.NO}>{t("NO")}</MenuItem>
                            </TextField>
                            <Typography className={classes.fieldTitle} variant="h6">
                                {t("CSNNUMBER")}
                            </Typography>
                            <TextField
                                fullWidth
                                id="catalogNumber"
                                type="text"
                                className={classes.fieldContent}
                                size="small"
                                placeholder={t("STANDARDCSN")}
                                inputRef={catalogNumberRegister.ref}
                                onChange={catalogNumberRegister.onChange}
                                onBlur={catalogNumberRegister.onBlur}
                                name={catalogNumberRegister.name}
                                inputProps={{
                                    defaultValue: standard.catalogNumber
                                }}
                            />
                            {errors.catalogNumber && (<div className={`${classes.formErrors} ${classes.fieldContent}`}>{t("FILLCSN")}</div>)}
                            <Typography className={classes.fieldTitle} variant="h6">
                                {t("STANDARDURL")}
                            </Typography>
                            <Typography variant="body1" className={classes.fieldContent}>
                                {t("URLGENERATED")}
                            </Typography>
                            <Typography className={classes.fieldTitle} variant="h6">
                                {t("ANNOTATIONTEXT")}
                            </Typography>
                            <TextField
                                fullWidth
                                id="annotation"
                                type="text"
                                className={classes.fieldContent}
                                size="small"
                                placeholder={t("ANNOTATIONPLACEHOLDER")}
                                inputRef={anotationRegister.ref}
                                onChange={anotationRegister.onChange}
                                onBlur={anotationRegister.onBlur}
                                name={anotationRegister.name}
                                inputProps={{
                                    defaultValue: standard.annotation
                                }}
                            />
                        </CardContent>

                        <CardActions className={classes.cardActions}>
                            <ButtonStyled
                                style={{marginRight: "auto"}}
                                text={t("BACK")}
                                onClick={() => navigate(-1)}/>
                            <ButtonStyled
                                text={props.type=="edit" ? t("SAVE") : t("CREATE")}
                                onClick={() => {
                                    setSubmitDialogOpen(true)
                                }}
                            />
                            <ConfirmDialog
                                title={t("SAVECHANGES")}
                                open={submitDialogOpen}
                                onClose={()=>setSubmitDialogOpen(false)}
                                onConfirm={onSubmit}
                            >
                                {props.type=="edit" ? t("SUBMITSTANDARD",{standardName: standard.name}) : t("CREATESTANDARD")}
                            </ConfirmDialog>
                        </CardActions>
                    </Card>
                </form>
                : <div/>}
        </Paper>
    );
}
