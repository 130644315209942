import React from 'react';
import Layout from '../../../components/Layout';

import StandardCreateEdit from "../../../components/standards/StandardsCreateEdit";

export default function StandardCreateEditPage( props ) {

    return (
        <Layout allowAnonymous={false}>
            <StandardCreateEdit type={"edit"} style={{minWidth:700, width:"60%", marginLeft: "auto", marginRight: "auto"}} id={props.params.standardId}/>
        </Layout>
    )
}